<template>
  <div>
    <CRow>
      <LoadingOverlay v-if="isFetchingGenericFilter" />
      <CCol md="12" class="m-1 pt-1">
        <GenericFilter
          :basicFilterlength="basicFilterlength"
          :filterSchema="currentPageSchema"
          :selectedFilters="selectedFilters"
          :filterDependencyObj="filterDependencyObj"
          :isFetchingFilter="isFetchingGenericFilter"
          :callback="updateRouterURL"
          :searchCallback="searchCallback"
          :filterAction="filterAction"
        />
        <CRow>
          <CCol md="6">
            <CandidateCount
              :totalCount="getTotalCount"
              :filterCount="getCandidateCount"
            />
          </CCol>
          <CCol md="6">
            <Pagination
              :total="getCandidateCount"
              :perPage="getCandidatePaginationLimit"
              @pagechanged="pagechanged"
              :maxVisibleButtons="isMobile ? 3 : 5"
              :position="isMobile ? 'left' : 'end'"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" class="mb-5">
        <CRow>
          <CCol col="12">
            <!-- <div class="text-color-red">
              <p>
                <b>IMPORTANT :</b> Where required, please confirm clinicians can
                obtain a valid NOC/Permission prior to selection/interview
              </p>
            </div> -->
          </CCol>
          <CCol
            v-for="(item, index) in getCandidates"
            :key="index"
            sm="6"
            md="3"
          >
            <div v-if="isFetchingCandidates" class="skeleton-card"></div>
            <CandidateList v-else :item="item" :index="index"/>
          </CCol>
          <CCol
            class="d-flex justify-content-center"
            v-if="
              !isFetchingGenericFilter &&
              !isFetchingCandidates &&
              !getCandidates.length
            "
          >
            <h1>No Data found!</h1>
          </CCol>
        </CRow>
        <CRow
          v-if="isFetchingCandidates"
          class="d-flex justify-content-around align-items-center"
          style="height: 200px"
        >
          <div class="has-loading-overlay">
            <div class="skeleton-card"></div>
          </div>
        </CRow>
        <div
          class="d-flex justify-content-center"
          v-if="
            getCandidates.length &&
            getCandidates.length < getCandidatePaginationLimit &&
            !isFetchingGenericFilter &&
            !isFetchingCandidates
          "
        >
          <span class="btn-pill btn-primary p-2"
            >No more records to display!</span
          >
        </div>
      </CCol>
    </CRow>
    <button
      v-if="isScrollTop"
      @click="scrollToTop()"
      id="btn-back-to-top"
      class="btn btn-primary btn-floating btn-lg"
      title="Go to top"
    >
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import CandidateList from "@/containers/CandidateList/CandidateList";
import CandidateCount from "@/components/Count";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, isObject, isEmptyObjectCheck } from "@/helpers/helper";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import Pagination from "@/components/reusable/Pagination";
import isJobSupplier from "@/mixins/isJobSupplier";
export default {
  name: "CandidateMain",
  mixins: [isJobSupplier],
  data() {
    return {
      currentUserRole: getScope(),
      isMobile: false,
      isScrollTop: false,
    };
  },
  components: {
    CandidateList,
    CandidateCount,
    GenericFilter,
    Pagination,
  },
  created() {
    this.setUpFilterSchema();
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    this.initAllFilters().then(async () => {
      await this.updateSelectedFilterFromFilterCode(
        this.$router.currentRoute.query
      );
      const pageNumber = parseInt(this.$router.currentRoute?.query?.page || 1);
      //Todo working on the two api calls issue
      this.candidatesListAction({
        query: this.getQueryResult.queryUrl,
        pageNumber,
        isTFWPool: this.isTFWPool,
      });
    });
    this.resetBackCount();
    //For setting getNextCandidateId and getPreviousCandidateId function according to current Page
    let payload = {
      currentPage: this.$router.currentRoute.name,
    };
    this.setCurrentNavigationCandidatesPage(payload);
    this.$store.commit("RESET_CANDIDATE_LIST_BACK_URL");
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    isJobSupplier() {
      if (isJobSupplier)
        this.setCurrentPageSchema({ schema: "candidateListUKSchema" });
    },
    "$route.name"() {
      this.setUpFilterSchema();
      this.getTotalCandidates();
    },
  },
  computed: {
    ...mapGetters([
      "getCandidates",
      "isFetchingCandidates",
      "noMoreDataFromCandidateList",
      "getCandidateCount",
      "getTotalCount",
      "currentPageSchema",
      "isFetchingGenericFilter",
      "filterDependencyObj",
      "selectedFilters",
      "getQueryResult",
      "isSelectedFilterUpdated",
      "getIsParentUser",
      "getOrgID",
      "getUser",
      "getOrgCountryId",
      "isJobSupplierFromAccessToken",
      "isUKMainAgency",
      "getCandidatePaginationLimit",
      "getIsParentUserFromAccessToken",
      "getCustomerTypeIDFromAccessToken"
    ]),
    basicFilterlength() {
      if ([Role.systemAdmin].includes(this.currentUserRole)) {
        return 11;
      } else {
        if (this.getOrgCountryId && this.getOrgCountryId == 182) {
          return 12;
        }
        return 10;
      }
    },
    isTFWPool() {
      const { params } = this.$route;
      return params?.customer_type === "super_customer" || false;
    },
  },
  methods: {
    ...mapActions([
      "resetBackCount",
      "setCurrentPageSchema",
      "candidatesListAction",
      "initAllFilters",
      "updateSelectedFilter",
      "clearSearchFilterWithUpdateSearch",
      "addSearchTerm",
      "updateSelectedFilterFromFilterCode",
      "setCurrentNavigationCandidatesPage",
      "clearAllSelectedFilters",
      "getGenericSpecialitySearchBasedOnId",
      "getGenericSpecialitySearchBasedOnCode",
      "scrollTop",
      "getTotalCandidates",
    ]),
    setUpFilterSchema() {
      this.setCurrentPageSchema({ schema: "newCandidateListSchema" });
      if (this.getIsParentUserFromAccessToken) {
        this.setCurrentPageSchema({
          schema: "candidateListSchemaForParentOrg",
        });
      }
      if (
        this.getOrgCountryId &&
        this.getOrgCountryId == 182 &&
        !this.isUKMainAgency &&
        !this.isJobSupplierFromAccessToken
      ) {
        // if organisation country is UK: 182 set UK Schema
        this.setCurrentPageSchema({ schema: "candidateListUKSchema" });
      } else if (this.isUKMainAgency) {
        // Set Pertemps Schema
        this.setCurrentPageSchema({ schema: "candidateListPertempsSchema" });
      }
      if (this.isTFWPool) {
        this.setCurrentPageSchema({ schema: "candidateListSchemaForTFW" });
      }
      if(this.getCustomerTypeIDFromAccessToken===5){
        this.setCurrentPageSchema({ schema: "candidateListSchemaForIms" 
      });
    }
    },
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    onBodyScroll(e) {
      if (e.target.scrollTop > 20) {
        this.isScrollTop = true;
      } else {
        this.isScrollTop = false;
      }
    },
    updateRouterURL(payload) {
      let appendAction = [];
      if (payload?.name === "specialitySearch")
        appendAction = [
          ...appendAction,
          this.getGenericSpecialitySearchBasedOnCode(payload),
        ];
      else appendAction = [...appendAction, this.updateSelectedFilter(payload)];
      Promise.all(appendAction).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction(isAsync = false) {
      if (isAsync) {
        const appendAction = this.getGenericSpecialitySearchBasedOnId();
        Promise.all([appendAction]).then(async (res) => {
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.candidatesListAction({
            query: this.getQueryResult.queryUrl,
            isTFWPool: this.isTFWPool,
          });
        });
      } else {
        this.initAllFilters().then(async () => {
          await this.clearAllSelectedFilters();
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          await this.candidatesListAction({
            query: this.getQueryResult.queryUrl,
            isTFWPool: this.isTFWPool,
          });
        });
      }
    },
    pagechanged(pageNumber) {
      let path = `${this.$route.path}?page=${pageNumber}`;
      if (this.getQueryResult?.routeUrl)
        path = `${this.$route.path}?page=${pageNumber}&${this.getQueryResult.routeUrl}`;
      this.$router.push({ path });
      this.candidatesListAction({
        pagination: false,
        query: this.getQueryResult.queryUrl,
        pageNumber,
        isPageChanged: true,
        isTFWPool: this.isTFWPool,
      });
    },
    scrollToTop() {
      this.scrollTop();
    },
  },
};
</script>
<style lang="scss" scoped>
.fs-14 {
  font-size: 14px;
}
#btn-back-to-top {
  position: fixed;
  right: 40px;
  bottom: 80px;
}
.btn-floating {
  border-radius: 50%;
  padding: 0;
  position: relative;
}
.btn-floating.btn-lg,
.btn-group-lg > .btn-floating.btn {
  width: 2.8125rem;
  height: 2.8125rem;
}
</style>